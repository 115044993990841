

const Definition = ({data}) => {
    return (
        <div className='w-1/2 sm:w-1/3 pb-8'>
            <div className='text-white bg-[#1d1c28] border border-gray-500 rounded-lg pl-4 shadow-xl shadow-black'>
                <h2 className='text-left text-md font-bold pt-4'>{data.word}</h2>
                <h4 className='text-left text-base italic'>noun</h4>
                <p className='text-left text-sm pt-4'>{data.definition_1}</p>
                <p className='text-left text-sm py-4'>{data.definition_2}</p>
            </div>
        </div>
    );
};

export default Definition;