import Employee from './Employee'
import cs_headshot from '../assets/cs-400w.jpeg'
import ks_headshot from '../assets/ks-400w.jpeg'
import tw_headshot from '../assets/tw-400w.jpeg'
import mz_headshot from '../assets/mz-400w.jpeg'

function Team() {
    return (
            <div className='w-full h-full pb-12 md:pb-0 md:h-screen mx-auto text-center flex flex-col md:items-center md:justify-center' id='team'>
                <h1 className='md:text-5xl sm:text-4xl text-3xl font-bold pt-4 md:pt-0 text-white antialiased'>Meet Our Team</h1>
                <div className='flex flex-col md:flex-row items-center justify-center pt-10 mx-auto'>
                    <div className='m-4 pb-4'>
                        <Employee src={ks_headshot} data={{name: 'Kyle Snell', role: 'Co-Founder', email: 'kyle.snell'}} />
                    </div>
                    <div className='m-4 pb-4'>
                        <Employee src={cs_headshot} data={{name: 'Colin Snell', role: 'Co-Founder', email: 'colin.snell'}} />
                    </div>
                    <div className='m-4 pb-4'>
                        <Employee src={mz_headshot} data={{name: 'Matthew Zampello', role: 'Developer', email: 'matthew.zampello'}} />
                    </div>
                    <div className='m-4 pb-4'>
                        <Employee src={tw_headshot} data={{name: 'Tyler Wooten', role: 'Developer', email: 'tyler.wooten'}} />
                    </div>
                </div>
            </div>
    );
}



export default Team;