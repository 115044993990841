// import headshot from '../assets/square_fake_headshot.jpg'

const Employee = ({src, data}) => {
    return (
        <div className='w-48 md:w-auto border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700'>
            <div className="flex flex-col items-center p-6 bg-[#1d1c28]">
                <img className="md:w-48 md:h-48 w-28 h-28 md:shink-0 mb-3 rounded-full shadow-lg" src={src} alt=""/>
                <h5 className="mb-1 lg:text-xl md:text-lg sm:text-sm font-medium text-gray-900 dark:text-white text-nowrap">{data.name}</h5>
                <span className="text-xs md:text-sm text-gray-500 dark:text-gray-400">{data.role}</span>
                <span className="text-xs md:text-sm pt-2 text-gray-500 dark:text-gray-400">{data.email}</span>
                <span className="text-xs md:text-sm text-gray-500 dark:text-gray-400">@laminarinnovation.com</span>
            </div>
        </div>
    );
}

export default Employee;