import Languages from "./Languages";


function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <div className="relative mx-10 sm:mx-28 pt-10">
            {/* <div className="custom-shape-divider-bottom-1724621134">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill"></path>
                </svg>
            </div> */}
            <div>
                <hr className="h-px mb-12 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            </div>
            <Languages />
            <div className="h-12">
                <h1 className="absolute left-10 bottom-2 p-2 text-gray-500 text-xs pb-2">Laminar Innovation, LLC | &copy; {currentYear}</h1>
            </div>
        </div>
    );
}


export default Footer;